<template>
  <div class="users">
    <h4 class="text-center mb-3">Users</h4>
    <div class="list mt-3">
      <b-row align-h="center">
        <b-col
          class="user"
          :class="{'disabled' : !allowSwitch}"
          cols="12"
          md="6"
          lg="6"
          v-for="user in users"
          :key="user.user_profiles_id">
          <div class="container">
            <b-overlay
              :show="!allowSwitch"
              variant="dark"
              :opacity="0.5"
              rounded="lg"
              @click="showPremiumModal('feature')">
              <template #overlay>
                <i class="fas fa-lock" style="color: transparent;"></i>
              </template>
              <i
                class="fas fa-times-circle remove"
                title="Remove"
                @click="removeUser(user)"
              ></i>
              <img
                class="img-fluid user-photo"
                :src="user.profile_img_url"
                :alt="`${user.profile_name} Profile Photo`"
                @error="setAltImage"
                @click="openEditUser(user)"
              />
            </b-overlay>
          </div>
          <p class="nickname mb-0"> {{ user.profile_name | truncate(13) }} </p>
        </b-col>

        <b-col
          class="user"
          cols="12"
          md="6"
          lg="6"
          v-if="allowAddUser">
          <div class="container">
            <img
              class="img-fluid add-user"
              src="../../assets/images/add_user_thumb.png"
              alt="Add User Profile"
              @click="openAddUser"
            />
            <p class="nickname mb-0">Add User</p>
          </div>
        </b-col>
      </b-row>

      <!-- <p
        class="users-text text-center"
        v-if="subscription.class_name === 'free' && !users">
        Subscribe to Healthy Hip Hop first before you can add Users!
      </p> -->
    </div>

    <b-row class="justify-content-center">
      <b-col cols="6">
        <b-button
          class="btn-h3-orange"
          type="button"
          @click="openChangePin"
          block>
          Change Pin
        </b-button>
      </b-col>
    </b-row>

    <!-- Modal -->
    <modals-container />

    <!-- Dialog -->
    <v-dialog />

    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FilterMixin from '@/mixins/FilterMixin';
  import PremiumMixin from '@/mixins/PremiumMixin';
  import AddUserProfile from './AddUserProfile';
  import EditUserProfile from './EditUserProfile';
  import PinModal from '@/components/pincode/PinModal';

  export default {
    mixins : [
      FilterMixin,
      PremiumMixin,
    ],
    data() {
      return {
        loading    : false,
        totalUsers : 0,
      }
    },
    computed : {
      ...mapGetters({
        users        : 'subscription/userProfiles',
        subscription : 'subscription/subscription',
      }),
      userLimit() {
        return parseInt(this.subscription.user_limit) - 1;
      },
      allowAddUser() {
        let allowed = false;
        if (this.userLimit > this.totalUsers)
          allowed = true;

        return allowed;
      },
      allowSwitch() {
        // let allowed = false;
        // if (this.$store.getters['subscription/subscription'].class_name !==
        //   'free')
        //   allowed = true;
          
        return true;
      },
    },
    components : {
      'AppLoader' : () => import('@/components/layout/AppLoader'),
    },
    watch : {
      users(val) {
        this.totalUsers = 0;
        if (val)
          this.totalUsers = val.length;
      },
    },
    methods : {
      
      /**
       * Open Add User Profile Modal
       */
      openAddUser() {
        this.$modal.show(AddUserProfile, {}, {
          height   : 'auto',
          width    : '800px',
          adaptive : true,
        }, {
          'update-users' : () => {
            this.$store.dispatch('subscription/getUserProfiles');
          },
        });
      },

      /**
       * Open Edit User Profile Modal
       * @param data
       */
      openEditUser(data) {
        this.$modal.show(EditUserProfile, {
          data : data,
        }, {
          height   : 'auto',
          width    : '800px',
          adaptive : true,
        }, {
          'update-users' : () => {
            this.$store.dispatch('subscription/getUserProfiles');
          },
        });
      },

      /**
       * Remove User Profile
       * @param data
       */
      removeUser(data) {
        this.$modal.show('dialog', {
          title   : 'Delete User Profile',
          text    : `Do you want to delete ${data.profile_name}?`,
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.delete('api/user/profile', {
                data : {
                  userProfId : data.user_profiles_id,
                },
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Deleted User Profile successfully!',
                });
                this.$store.dispatch('subscription/getUserProfiles');
                this.$modal.hide('dialog');
              });
            },
          }, {
            title : 'No',
          } ],
        })
        
      },

      /**
       * Set Alternative Image
       * @param event
       */
      setAltImage(event) {
        event.target.src =
          require('../../assets/images/sample_user_profile.png');
      },

      /**
       * Open Change Pin Modal
       */
      openChangePin() {
        this.$modal.show(PinModal, {
          action : 'change',
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },
    },
    mounted() {
      this.$store.dispatch('subscription/getUserProfiles');
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .users {
    padding: 1.5rem;
    width: 100%;
    background-color: rgba(42, 42, 252, 0.5);
    border: 5px solid rgba(231, 147, 45, 0.8);
    border-radius: 1rem;
    box-shadow: 3px 10px 10px rgba(0,0,0,0.5);
  }

  .list {
    margin-bottom: 2rem;
    padding: 2rem 5rem;
    background-color: #fff;
    border: 5px solid #e7932d;
    border-radius: 1rem;
  }

  .nickname {
    padding: 1rem 0;
    color: #1e21c4;
    font-family: 'WickedMouse' !important;
    font-size: 1em !important;
    text-align: center;
  }

  .user {
    &:hover {
      cursor: pointer;
      .container {
        .remove {
          display: inline-block;
        }
        .user-photo {
          border: 3px solid #1e21c4;
        }
      }
    }

    .container {
      position: relative;
      padding: 0;

      .remove {
        display: none;
        position: absolute;
        padding: 0.1rem;
        top: -7%;
        right: -7%;
        color: rgba(209, 20, 16, 0.7);
        background-color: #fff;
        border-radius: 1rem;
        font-size: 1.75rem;

        &:hover {
          color: #dc3545;
        }
      }
    }

    .user-photo {
      width: 100%;
      height: 22.5vh;
      background-image: url(../../assets/images/user-profile-bg.png);
      background-size: contain;
      border: 3px solid #d79b21;
      border-radius: 0.25rem;
    }

    .add-user {
      width: 100%;
      border: 3px solid #d79b21;
      border-radius: 0.25rem;
      &:hover {
        border: 3px solid #1e21c4;
      }
    }
  }

  .disabled {
    &:hover {
      .remove {
        display: none !important;
      }
      .user-photo {
        border: 3px solid #999C9F !important;
      }
    }
    .user-photo {
      border: 3px solid #999C9F;
    }
    .nickname {
      color: #999C9F;
    }
  }

  .users-text {
    color: #1e21c4 !important;
    font-size: 1.2em !important;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .user-photo {
      height: auto !important;
    }
  }
  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .user-photo {
      height: auto !important;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .user-photo {
      height: auto !important;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .user-photo {
      height: auto !important;
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .user-photo {
      height: auto !important;
    }
  }
  @media only screen and (min-width: $special) {
    .user-photo {
      height: auto !important;
    }
  }
  @media only screen and (min-width: $xl2-min) {}
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {}
</style>